/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// @mui material components
import Switch from "@mui/material/Switch";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Jellybean React context
import { useMaterialUIController, setDarkMode } from "context";

function ApplicationSettings() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const handleDarkMode = () => {
    const newDarkModeState = !darkMode;
    setDarkMode(dispatch, newDarkModeState);
    // Save the new theme preference in localStorage
    localStorage.setItem("darkMode", JSON.stringify(newDarkModeState));
  };

  // Fetch theme preference from localStorage when the component mounts
  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode !== null) {
      setDarkMode(dispatch, JSON.parse(storedDarkMode));
    }
  }, [dispatch]);

  return (
    <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
      <MDBox mt={3}>
        <MDTypography variant="h6" fontWeight="Medium" textTransform="capitalize">
          application settings
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
        <MDBox mt={0.5}>
          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <MDBox width="80%" ml={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Light / Dark Theme
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default ApplicationSettings;
