import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import KitchenIcon from "@mui/icons-material/Kitchen";
import DoNotStepIcon from "@mui/icons-material/DoNotStep";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

export const preloadedPrompts = [
  {
    icon: <ElectricCarIcon />,
    text: "Race car zooming by in a video game similar to GTA",
  },
  {
    icon: <KitchenIcon />,
    text: "Sounds of a kitchen relevant for a restaurant simulation",
  },
  {
    icon: <DoNotStepIcon />,
    text: "Footsteps of a monster in a tunnel",
  },
  {
    icon: <VideogameAssetIcon />,
    text: "Shooting sounds in a retro video game",
  },
  {
    icon: <CorporateFareIcon />,
    text: "Collapsed building with fire sparks due to bombing",
  },
];
