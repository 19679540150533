import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";

function WaitingListPopup({ open, onSubmitCode, requestNewCode }) {
  const [waitingListCode, setWaitingListCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [requestDetails, setRequestDetails] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleBack = () => {
    setShowSuccessMessage(false);
    setShowRequestForm(false);
  };

  const handleSubmitCode = () => {
    if (waitingListCode.trim() === "") {
      setErrorMessage("Please enter a valid code.");
      return;
    }
    onSubmitCode(waitingListCode);
  };

  const handleRequestSubmit = () => {
    if (requestDetails.trim() === "") {
      setErrorMessage("Please provide details about your use case.");
      return;
    }
    requestNewCode(requestDetails);
    setShowSuccessMessage(true);
    setShowRequestForm(false);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Access JellyBean AI</DialogTitle>
      <DialogContent>
        {showSuccessMessage ? (
          <p>
            We appreciate your interest in JellyBean AI. Hold tight, our team will get back to you
            soon via email. If you have any questions, please join us on our community WhatsApp
            channel{" "}
            <a
              href="https://chat.whatsapp.com/LGTUCFTgvZdI3RnXAYUKXG"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#4700D8", textDecoration: "underline" }}
            >
              here
            </a>
            .
          </p>
        ) : showRequestForm ? (
          <>
            <p>
              Tell us about how you plan to use AI in your game development workflows. Please be
              descriptive so we can understand your use case better & grant access to our platform.
            </p>
            &nbsp;
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={requestDetails}
              onChange={(e) => {
                setRequestDetails(e.target.value);
                setErrorMessage("");
              }}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </>
        ) : (
          <>
            <p>
              Welcome to JellyBean! To access the platform, please enter your waiting list code. If
              you don’t have a code, you can request one below.
            </p>
            &nbsp;
            <TextField
              fullWidth
              label="Waiting List Code"
              value={waitingListCode}
              onChange={(e) => {
                setWaitingListCode(e.target.value);
                setErrorMessage("");
              }}
              error={!!errorMessage}
              helperText={errorMessage}
              style={{ marginTop: "10px" }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {showRequestForm ? (
          <MDButton
            onClick={handleRequestSubmit}
            variant="contained"
            style={{ backgroundColor: "#4700D8", color: "#fff" }}
          >
            Submit Request
          </MDButton>
        ) : !showSuccessMessage ? (
          <>
            <MDButton
              onClick={handleSubmitCode}
              variant="contained"
              style={{ backgroundColor: "#4700D8", color: "#fff" }}
            >
              Submit Code
            </MDButton>
            <MDButton onClick={() => setShowRequestForm(true)} variant="outlined" color="primary">
              Request Access Code
            </MDButton>
          </>
        ) : (
          <MDButton
            onClick={handleBack}
            variant="contained"
            style={{ backgroundColor: "#4700D8", color: "#fff" }}
          >
            Back
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

WaitingListPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmitCode: PropTypes.func.isRequired,
  requestNewCode: PropTypes.func.isRequired,
};

export default WaitingListPopup;
