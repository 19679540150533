/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Jellybean React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { FormHelperText, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDInput from "../../../../components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "../../../../components/MDButton";

function ProfileInfoCard({
  title,
  description,
  info,
  social,
  actionTooltip,
  shadow,
  updateUserDetails,
}) {
  const { socialMediaColors } = colors;
  const { size } = typography;

  const [showDialog, setShowDialog] = useState(false);
  const [userDetails, setUserDetails] = useState(info);
  const [displayDetails, setDisplayDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [nameError, setNameError] = useState(null);

  const formatValue = (value) => {
    if (
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value === "")
    ) {
      return <i>Not Specified</i>;
    } else if (typeof value === "object") {
      return <code>{JSON.stringify(value, null, 2)}</code>;
    } else {
      return <>{value}</>;
    }
  };

  const transformCamelCaseToTitleCase = (input) => {
    return input
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before uppercase letters
      .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
  };

  useEffect(() => {
    setUserDetails(info);

    const details = Object.keys(info).reduce((list, infoKey) => {
      const detail = {
        key: infoKey,
        label: transformCamelCaseToTitleCase(infoKey),
        value: info[infoKey],
      };

      return [...list, detail];
    }, []);

    setDisplayDetails(details);
  }, [info]);

  useEffect(() => {
    setSubmitting(false);
    setShowDialog(false);
  }, [info]);

  // Render the card info items
  const renderItem = ({ label, value }) => {
    return (
      <MDBox key={label} display="flex" py={1} pr={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          {formatValue(value)}
        </MDTypography>
      </MDBox>
    );
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    boxShadow: 24,
    p: 4,
  };

  // Render the card social media icons
  const renderSocial = social.map(({ link, icon, color }) => (
    <MDBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });

    if (name === "fullName") {
      // TODO: restrict characters that can be used in name
      if (value === "") {
        setNameError("Name is required field");
      } else if (value.length > 1024) {
        setNameError("Name cannot be greater than 1024 characters");
      } else {
        setNameError(null);
      }
    }
  };

  const getInputComponent = (detail) => {
    const { key, label } = detail;
    let value = userDetails[key];

    if (key === "email") {
      return (
        <MDInput
          type="email"
          label="Email"
          value={value}
          disabled={true}
          style={{ width: "100%" }}
        />
      );
    } else {
      if (key === "dateOfBirth" && value === "") {
        let dt = new Date();
        value = `${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()}`;
      }

      return (
        <>
          <MDInput
            type={key === "dateOfBirth" ? "date" : "text"}
            label={label}
            name={key}
            value={value}
            style={{ width: "100%" }}
            disabled={submitting}
            required={key === "fullName"}
            onChange={handleChange}
            error={key === "fullName" && !!nameError}
          />
          {key === "fullName" && !!nameError && <FormHelperText error>{nameError}</FormHelperText>}
        </>
      );
    }
  };

  const saveUserDetails = (e) => {
    e.preventDefault();

    if (!!nameError) {
      // TODO: Show notification to fix errors
    } else {
      setSubmitting(true);
      updateUserDetails(userDetails.fullName, userDetails.studioName, userDetails.dateOfBirth);
    }
  };

  const closeModel = () => {
    setShowDialog(false);
    setUserDetails(info);
  };

  return (
    <>
      <Card sx={{ boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component={Link}
            variant="body2"
            color="secondary"
            onClick={() => setShowDialog(true)}
          >
            <Tooltip title={actionTooltip} placement="top">
              <Icon>edit</Icon>
            </Tooltip>
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDBox>{displayDetails.map((detail) => renderItem(detail))}</MDBox>
        </MDBox>
      </Card>

      {showDialog && (
        <Modal open={showDialog} onClose={closeModel}>
          <MDBox mt={5} mb={3} sx={modalStyle} bgColor="light" borderRadius="md">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              color="text"
              mb={4}
            >
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Update {title}
              </MDTypography>

              <MDTypography component={Link} variant="body2" color="secondary" onClick={closeModel}>
                <Tooltip title="Close Dialog" placement="top">
                  <Icon>close</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>

            <Grid container>
              <MDBox
                component="form"
                onSubmit={saveUserDetails}
                style={{ width: "100%" }}
                noValidate
              >
                {displayDetails.map((detail) => (
                  <Grid item xs={12} key={detail.key} mb={4}>
                    {getInputComponent(detail)}
                  </Grid>
                ))}

                <MDButton type="submit" variant="gradient" color="primary" disabled={submitting}>
                  {submitting ? <CircularProgress size={24} color="inherit" /> : "Update Info"}
                </MDButton>
              </MDBox>
            </Grid>
          </MDBox>
        </Modal>
      )}
    </>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionTooltip: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  updateUserDetails: PropTypes.func,
};

export default ProfileInfoCard;
