/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

function SubscriptionDetails({ tier, startDate, endDate, beansAllocated, beansBalance }) {
  const { getAccessTokenSilently } = useAuth0(); // Fetch user from Auth0

  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [dates, setDates] = useState();
  const [usage, setUsage] = useState();

  const getBeansUsageData = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      // ${process.env.REACT_APP_API_URL}
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/beans-usage`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      if (response?.data?.dates && response?.data?.values) {
        setDates(response.data.dates);
        setUsage(response.data.values);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    getBeansUsageData();
  }, []);

  useEffect(() => {
    if (startDate !== "") {
      const sDate = new Date(startDate);
      setFormattedStartDate(sDate.toLocaleString());
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== "") {
      const eDate = new Date(endDate);
      setFormattedEndDate(eDate.toLocaleString());
    }
  }, [endDate]);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Current Subscription Details
        </MDTypography>
      </MDBox>
      <MDBox py={1} px={2} lineHeight={1.25}>
        <MDBox display="flex" pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            Subscription Tier: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {tier}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            Beans Allocated: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {beansAllocated}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            Remaining Beans:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {beansBalance}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            Start Date: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {formattedStartDate}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            End Date: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {formattedEndDate}
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox>
        <DefaultLineChart
          // icon={{ color: "info", component: "date_range" }}
          title="Last 10 Days Usage"
          chart={{
            labels: dates,
            datasets: [
              {
                label: "Beans Used",
                color: "info",
                data: usage,
              },
            ],
          }}
        />
      </MDBox>
    </Card>
  );
}

SubscriptionDetails.propTypes = {
  tier: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  beansAllocated: PropTypes.string.isRequired,
  beansBalance: PropTypes.string.isRequired,
};

export default SubscriptionDetails;
