/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Switch from "@mui/material/Switch";
import { useAuth0 } from "@auth0/auth0-react"; // Add Auth0 hook

import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player";
import axios from "axios";

// @mui material components
import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "@mui/material/Slider";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Jellybean React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useMaterialUIController, setSimpleMode } from "context";

// Dashboard components
import HistoryMultiple from "layouts/SoundDesign/components/HistoryMultiple"; // Assuming you have this import
import { preloadedPrompts } from "./preloaded-prompts";

function SFXStudio() {
  const [controller, dispatch, transparentNavbar] = useMaterialUIController();
  const { darkMode, simpleMode } = controller;
  const [scriptText, setScriptText] = useState(""); // State to store the script text
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [generating, setGenerating] = useState(false); // State for loading indicator
  const [historyData, setHistoryData] = useState([]); // State to store the history data
  const [responseMessage, setResponseMessage] = useState(""); // State to store the API response message
  const [audioUrl, setAudioUrl] = useState(""); // State to store the audio URL for playback
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [promptInfluence, setPromptInfluence] = useState(0.3);
  const [duration, setDuration] = useState(8);

  const { getAccessTokenSilently } = useAuth0(); // Get access token hook from Auth0

  const handleSimpleMode = () => {
    const newSimpleModeState = !simpleMode;
    setSimpleMode(dispatch, newSimpleModeState);
    // Save the new theme preference in localStorage
    localStorage.setItem("simpleMode", JSON.stringify(newSimpleModeState));
  };

  // Reference to the input field
  const inputRef = useRef(null);

  // Focus the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus to the input field
    }
  }, []); // Empty dependency array to run only once on mount

  // Fetch history data from /api/query-tts-responses
  const fetchHistory = async () => {
    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/query-sfx-responses`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setHistoryData(response.data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  // Fetch history when the component mounts
  useEffect(() => {
    fetchHistory();
  }, []);

  // Handle the Generate button click
  const handleGenerateClick = async () => {
    if (scriptText.trim() === "") {
      setResponseMessage("Please enter a script to generate speech.");
      openErrorSB(); // Open error notification
      return;
    }

    setLoading(true);
    setGenerating(true);
    setResponseMessage(""); // Clear any previous messages

    let retries = 6; // Retry limit
    let success = false; // Track if the request succeeds

    while (retries > 0 && !success) {
      try {
        // Get the access token from Auth0
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
        });

        // Prepare the payload
        const payload = {
          text: scriptText, // Use the script text as input for SFX generation
          duration_seconds: duration,
          prompt_influence: promptInfluence,
        };

        // Submit the script for SFX generation with the token in the headers
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/submit-sfx`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
            },
          }
        );

        // If the request succeeds
        setResponseMessage("SFX request submitted successfully.");
        fetchHistory(); // Refresh the history after submission
        setLoading(false);
        setGenerating(false);
        // openSuccessSB(); // Open success notification
        success = true; // Mark request as successful
      } catch (error) {
        if (error.response && error.response.status === 429) {
          console.log("Rate limit exceeded, retrying..."); // Log the error
          retries -= 1; // Decrement retry count
          if (retries > 0) {
            await new Promise((resolve) => setTimeout(resolve, 10 * 1000)); // Wait 10 seconds before retrying
          } else {
            setResponseMessage("Rate limit exceeded. Please try again later.");
            openErrorSB(); // Open error notification
          }
        } else {
          // Handle non-429 errors
          if (error.response && error.response.data) {
            const { message, subscription_end_date } = error.response.data;

            // Construct a detailed error message
            const errorMessage = subscription_end_date
              ? `${message}. Subscription expired on: ${new Date(
                  subscription_end_date
                ).toLocaleString()}`
              : message;

            // Display the detailed error message
            setResponseMessage(errorMessage);
          } else {
            // Fallback for unexpected errors
            setResponseMessage("Failed to submit SFX request.");
          }
          setLoading(false);
          setGenerating(false);
          openErrorSB(); // Open error notification
          break; // Exit the loop for non-429 errors
        }
      }
    }

    setLoading(false);
    setGenerating(false);
  };

  // Handle play action for a specific UUID
  const handlePlayClick = async (uuid, text) => {
    setAudioUrl("");
    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API
      });

      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-sfx-uploads/${uuid}.mpga`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const audioUrl = response.data.url; // Get the audio URL from the response
      setAudioUrl(audioUrl); // Store the audio URL in state
      setScriptText(text); // Store the script text in state
      setResponseMessage("Audio is ready for playback.");
      setLoading(false);
      // openSuccessSB(); // Open success notification
    } catch (error) {
      // Extract error details from the response
      if (error.response && error.response.data) {
        const { message, subscription_end_date } = error.response.data;

        // Construct a detailed error message
        const errorMessage = subscription_end_date
          ? `${message}. Subscription expired on: ${new Date(
              subscription_end_date
            ).toLocaleString()}`
          : message;

        // Display the detailed error message
        setResponseMessage(errorMessage);
      } else {
        // Fallback for unexpected errors
        setResponseMessage("Failed to download audio.");
      }
      setLoading(false);
      openErrorSB(); // Open error notification
    }
  };

  // Handle download action for a specific UUID
  const handleDownloadClick = async (uuid) => {
    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API
      });

      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-sfx-uploads/${uuid}.mpga`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const downloadUrl = response.data.url; // Get the download URL from the response

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${uuid}.mpga`); // Set the download attribute with the filename
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to start download
      document.body.removeChild(link); // Remove the link after the download starts

      setResponseMessage("Download started successfully.");
      // openSuccessSB(); // Open success notification
    } catch (error) {
      // Extract error details from the response
      if (error.response && error.response.data) {
        const { message, subscription_end_date } = error.response.data;

        // Construct a detailed error message
        const errorMessage = subscription_end_date
          ? `${message}. Subscription expired on: ${new Date(
              subscription_end_date
            ).toLocaleString()}`
          : message;

        // Display the detailed error message
        setResponseMessage(errorMessage);
      } else {
        // Fallback for unexpected errors
        setResponseMessage("Failed to download audio.");
      }
      setLoading(false);
      openErrorSB(); // Open error notification
    } finally {
      setLoading(false);
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="notifications"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const promptMarks = [
    {
      value: 0,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          More Creative
        </MDTypography>
      ),
    },
    {
      value: 1,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Follow Prompt
        </MDTypography>
      ),
    },
  ];

  const durationMarks = [
    {
      value: 0.5,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          0.5
        </MDTypography>
      ),
    },
    {
      value: 22,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          22
        </MDTypography>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={9}>
              <MDBox display="flex" flexDirection="column">
                <Card sx={{ height: "100%", width: "100%", boxShadow: "none" }}>
                  <MDBox pt={4} pb={3} px={3}>
                    <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                      <span style={{ fontSize: "15px", marginRight: "8px" }}>Simple</span>
                      <Switch checked={simpleMode} onChange={handleSimpleMode} />
                      <span style={{ fontSize: "15px", marginLeft: "8px" }}>Advanced</span>
                    </MDBox>
                    <MDBox component="form" role="form">
                      <MDBox mb={2} position="relative">
                        <MDInput
                          label="Prompt"
                          placeholder="Welcome to the land of Jellybean, where epic adventures come alive through sound. Need the roar of a dragon, the clash of swords, or the ambiance of a mystical forest? Start creating now and bring your game’s world to life with immersive sound effects!"
                          rows={10}
                          multiline
                          fullWidth
                          value={scriptText}
                          onChange={(e) => setScriptText(e.target.value)} // Update script text
                          inputRef={inputRef} // Attach the reference to the input
                        />
                      </MDBox>
                      {simpleMode && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} p={1}>
                            <MDBox>
                              <MDTypography variant="h6" gutterBottom>
                                Prompt Influence
                              </MDTypography>
                              <MDTypography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ fontSize: "0.9rem" }}
                              >
                                Slide the scale to make your generation perfectly adhere to your
                                prompt or allow a little creativity
                              </MDTypography>
                              <Slider
                                sx={{ width: "75%", marginLeft: "24px" }}
                                min={0}
                                max={1}
                                step={0.1}
                                defaultValue={0.3}
                                value={promptInfluence}
                                onChange={(e, newValue) => setPromptInfluence(newValue)}
                                valueLabelDisplay="off"
                                aria-labelledby="prompt-influence-slider"
                                marks={promptMarks}
                              />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={6} p={1}>
                            <MDBox>
                              <MDTypography variant="h6" gutterBottom>
                                Duration
                              </MDTypography>
                              <MDTypography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ fontSize: "0.9rem" }}
                              >
                                Determine how long your generations should be.
                                <br />
                                Choose between 0.5 and 22 seconds
                              </MDTypography>
                              <Slider
                                sx={{ width: "75%", marginLeft: "24px" }}
                                min={0.5}
                                max={22}
                                step={0.5}
                                defaultValue={8}
                                value={duration}
                                onChange={(e, newValue) => setDuration(newValue)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value} secs`}
                                aria-labelledby="duration-slider"
                                marks={durationMarks}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      )}

                      <MDBox my={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={handleGenerateClick}
                          disabled={loading} // Disable button while loading
                        >
                          {loading ? <CircularProgress size={24} color="inherit" /> : "Generate"}
                          {/* Show loading icon */}
                        </MDButton>
                      </MDBox>
                      {generating && (
                        <span style={{ fontSize: "0.875rem", fontStyle: "italic", color: "#555" }}>
                          Hang tight—your masterpiece is brewing in the Jellybean forge!
                        </span>
                      )}
                      {/* Audio player */}
                      {audioUrl && (
                        <MDBox mt={2}>
                          <AudioPlayer
                            autoPlay
                            src={audioUrl}
                            onPlay={(e) => console.log("Playing")}
                            // Customize more options below
                            showJumpControls={false} // Hide forward and backward jump controls
                            customAdditionalControls={[]} // Hide extra controls
                            customVolumeControls={[]} // Hide volume controls if needed
                            layout="horizontal" // Set the layout style
                            className="custom-audio-player"
                            // You can add more props to customize the player further
                          />
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Card>

                <Card sx={{ boxShadow: "none", paddingTop: "0.5rem", marginTop: "1rem" }}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <MDTypography variant="body2" gutterBottom>
                      Try one of the following examples to get started
                    </MDTypography>

                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      flexWrap="wrap"
                      p={2}
                    >
                      {preloadedPrompts.map(({ icon, text }, index) => (
                        <MDButton
                          key={index}
                          variant="outlined"
                          color="info"
                          size="small"
                          startIcon={icon}
                          sx={{ margin: "2px", textTransform: "capitalize" }}
                          onClick={() => setScriptText(text)}
                        >
                          {text}
                        </MDButton>
                      ))}
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              {/* Pass the fetched history data to History component */}
              <HistoryMultiple
                historyItems={historyData}
                handlePlayClick={handlePlayClick}
                handleDownloadClick={handleDownloadClick}
                itemTimeKey="created_time"
                sectionName="My Sound Assets"
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Render success and error snackbars */}
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default SFXStudio;
