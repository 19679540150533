import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Jellybean React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function History({
  historyItems,
  handlePlayClick,
  handleDownloadClick,
  itemIdKey,
  itemTimeKey,
  sectionName = "History",
  itemsPerPage = 3,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate pagination data
  const totalPages = Math.ceil(historyItems.length / itemsPerPage);
  const displayedItems = historyItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const truncateText = (text, length = 20) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {sectionName}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {displayedItems.length > 0 ? (
          displayedItems.map((item, index) => (
            <MDBox key={index} mb={3}>
              <TimelineItem
                color="info"
                icon="audiotrack"
                title={
                  <Tooltip title={item.text} arrow>
                    <span>{truncateText(item.text, 30)}</span>
                  </Tooltip>
                }
                dateTime={new Date(item[itemTimeKey]).toLocaleString()}
                lastItem={index === displayedItems.length - 1}
                description={
                  item.file_ids && item.file_ids.length > 0 ? (
                    <MDBox mt={2}>
                      {item.file_ids.map((fileId, fileIndex) => (
                        <MDBox key={fileId} display="flex" alignItems="center" mb={1}>
                          <MDTypography variant="caption" fontWeight="medium">
                            Sample {fileIndex + 1}
                          </MDTypography>
                          <MDBox>
                            <IconButton
                              color="info"
                              size="small"
                              onClick={() => handlePlayClick(fileId, item.text)}
                            >
                              <PlayArrowIcon />
                            </IconButton>
                            <IconButton
                              color="info"
                              size="small"
                              onClick={() => handleDownloadClick(fileId)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </MDBox>
                        </MDBox>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="caption" color="textSecondary">
                      No files available.
                    </MDTypography>
                  )
                }
              />
            </MDBox>
          ))
        ) : (
          <MDTypography variant="body2" fontWeight="regular">
            No history available.
          </MDTypography>
        )}
      </MDBox>
      <MDBox display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          color="info"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "0.75rem",
              padding: "4px",
              marginBottom: "8px",
            },
          }}
        />
      </MDBox>
    </Card>
  );
}

// PropTypes validation for historyItems
History.propTypes = {
  historyItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      file_ids: PropTypes.arrayOf(PropTypes.string), // Ensure file_ids is an array of strings
      created_time: PropTypes.string.isRequired,
    })
  ).isRequired,
  handlePlayClick: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
  itemIdKey: PropTypes.string,
  itemTimeKey: PropTypes.string.isRequired,
  sectionName: PropTypes.string,
  itemsPerPage: PropTypes.number,
};

export default History;
