/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Jellybean React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Jellybean React layouts
import Profile from "layouts/profile";
import VoxStudio from "layouts/SoundDesign/VoxStudio";
import SFXStudio from "layouts/SoundDesign/SFXStudio";
import MusicStudio from "layouts/SoundDesign/MusicStudio";
import ImageStudio from "layouts/GameDesign/ImageStudio";
import ThreeDStudio from "layouts/GameDesign/ThreeDStudio";
import ImagineStudio from "layouts/GameDesign/ImagineStudio";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "title",
    title: "Sound Design",
    key: "sound-design",
  },
  {
    type: "collapse",
    name: "Vox Studio",
    key: "vox-studio",
    icon: <Icon fontSize="small">mic</Icon>,
    route: "/vox-studio",
    component: <VoxStudio />,
  },
  {
    type: "collapse",
    name: "SFX Studio",
    key: "sfx-studio",
    icon: <Icon fontSize="small">surround_sound</Icon>,
    route: "/sfx-studio",
    component: <SFXStudio />,
  },
  {
    type: "collapse",
    name: "Music Studio",
    key: "music-studio",
    icon: <Icon fontSize="small">music_note</Icon>,
    route: "/music-studio",
    component: <MusicStudio />,
  },
  {
    type: "divider",
    key: "divider",
  },
  {
    type: "title",
    title: "Game Design",
    key: "game-design",
  },
  {
    type: "collapse",
    name: "Image Studio",
    key: "image-studio",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/image-studio",
    component: <ImageStudio />,
  },
  {
    type: "collapse",
    name: "3D Studio",
    key: "3d-studio",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/3d-studio",
    component: <ThreeDStudio />,
  },
  {
    type: "collapse",
    name: "Imagine Studio",
    key: "imagine-studio",
    icon: <Icon fontSize="small">palette</Icon>,
    route: "/imagine-studio",
    component: <ImagineStudio />,
  },
  {
    type: "divider",
    key: "divider-1",
  },
  // {
  //   type: "title",
  //   title: "Docs & Resources",
  //   key: "docsandresources",
  // },
  // {
  //   type: "collapse",
  //   name: "Documentation",
  //   key: "documentation",
  //   icon: <Icon fontSize="small">image</Icon>,
  //   // route: "/promotional-content",
  //   // component: <Documentation />,
  // },
  // {
  //   type: "collapse",
  //   name: "Help Center",
  //   key: "help-center",
  //   icon: <Icon fontSize="small">image</Icon>,
  //   // route: "/skins",
  //   // component: <Skins />,
  // },
  // {
  //   type: "collapse",
  //   name: "Updates & Blog",
  //   key: "updates-and-blog",
  //   icon: <Icon fontSize="small">image</Icon>,
  //   // route: "/skins",
  //   // component: <Skins />,
  // },
  {
    type: "divider",
    key: "divider-2",
  },
  {
    type: "title",
    title: "Join Our Community",
    key: "Join Our Community",
  },
  {
    type: "collapse",
    name: "Whatsapp",
    key: "Whatsapp",
    icon: <Icon fontSize="small">image</Icon>,
    href: "https://chat.whatsapp.com/LGTUCFTgvZdI3RnXAYUKXG",
    // component: <Documentation />,
  },
  {
    type: "collapse",
    name: "Discord",
    key: "Discord",
    icon: <Icon fontSize="small">image</Icon>,
    href: "https://discord.gg/4wgp8eqD",
    // component: <Skins />,
  },
  {
    type: "divider",
    key: "divider-3",
  },
  {
    key: "profile",
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
