import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import AudioPlayer from "react-h5-audio-player";

import HistoryMultiple from "../../../SoundDesign/components/HistoryMultiple";
import MDBox from "../../../../components/MDBox";
import MDSnackbar from "../../../../components/MDSnackbar";
import History from "../../../SoundDesign/components/History";
import MDTypography from "../../../../components/MDTypography";

function ProfileAssets({ userName }) {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState();

  const [voiceAssets, setVoiceAssets] = useState([]);
  const [soundAssets, setSoundAssets] = useState([]);

  const [audioUrl, setAudioUrl] = useState("");
  const [audioInfo, setAudioInfo] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState(null);

  const updateToken = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
    });

    setToken(token);
  };

  const fetchVoiceAssets = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/query-tts-responses`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setVoiceAssets(response.data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  const fetchSoundAssets = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/query-sfx-responses`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setSoundAssets(response.data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  useEffect(() => {
    updateToken();
  }, []);

  useEffect(() => {
    if (!!token) {
      fetchVoiceAssets();
      fetchSoundAssets();
    }
  }, [token]);

  const handleVoicePlay = async (uuid, text) => {
    setAudioUrl("");
    setAudioInfo(null);
    setErrorSnackbarMessage("");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-tts-uploads/${uuid}.wav`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const audioUrl = response.data.url; // Get the audio URL from the response
      setAudioUrl(audioUrl); // Store the audio URL in state
      setAudioInfo({
        type: "Voice Asset",
        text: text,
      });
    } catch (error) {
      // Extract error details from the response
      if (error.response && error.response.data) {
        const { message, subscription_end_date } = error.response.data;

        // Construct a detailed error message
        const errorMessage = subscription_end_date
          ? `${message}. Subscription expired on: ${new Date(
              subscription_end_date
            ).toLocaleString()}`
          : message;

        // Display the detailed error message
        setErrorSnackbarMessage(errorMessage);
      } else {
        // Fallback for unexpected errors
        setErrorSnackbarMessage("Failed to play Voice Asset audio.");
      }
      setShowSnackbar(true);
    }
  };

  const handleVoiceDownload = async (uuid) => {
    try {
      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-tts-uploads/${uuid}.wav`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const downloadUrl = response.data.url; // Get the download URL from the response

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${uuid}.wav`); // Set the download attribute with the filename
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to start download
      document.body.removeChild(link); // Remove the link after the download starts

      console.log("Voice asset download started successfully");
    } catch (error) {
      console.error("Error downloading audio:", error);
    }
  };

  const handleSoundPlay = async (uuid, text) => {
    setAudioUrl("");
    setAudioInfo(null);
    setErrorSnackbarMessage("");

    try {
      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-sfx-uploads/${uuid}.mpga`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const audioUrl = response.data.url; // Get the audio URL from the response
      setAudioUrl(audioUrl); // Store the audio URL in state
      setAudioInfo({
        type: "Sound Asset",
        text: text,
      });
    } catch (error) {
      // Extract error details from the response
      if (error.response && error.response.data) {
        const { message, subscription_end_date } = error.response.data;

        // Construct a detailed error message
        const errorMessage = subscription_end_date
          ? `${message}. Subscription expired on: ${new Date(
              subscription_end_date
            ).toLocaleString()}`
          : message;

        // Display the detailed error message
        setErrorSnackbarMessage(errorMessage);
      } else {
        // Fallback for unexpected errors
        setErrorSnackbarMessage("Failed to play Sound Asset audio.");
      }
      setShowSnackbar(true);
    }
  };

  const handleSoundDownload = async (uuid) => {
    try {
      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-sfx-uploads/${uuid}.mpga`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const downloadUrl = response.data.url; // Get the download URL from the response

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${uuid}.mpga`); // Set the download attribute with the filename
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to start download
      document.body.removeChild(link); // Remove the link after the download starts

      console.log("Sound asset download started successfully");
    } catch (error) {
      console.error("Error downloading audio:", error);
    }
  };

  return (
    <>
      <Grid container spacing={2} mb={4} px={3}>
        <Grid item xs={12} md={6}>
          <History
            historyItems={voiceAssets}
            handleDownloadClick={handleVoiceDownload}
            handlePlayClick={handleVoicePlay}
            itemIdKey="job_id"
            itemTimeKey="submission_time"
            sectionName={`${userName}'s Voice Assets`}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <HistoryMultiple
            historyItems={soundAssets}
            handleDownloadClick={handleSoundDownload}
            handlePlayClick={handleSoundPlay}
            itemIdKey="uuid"
            itemTimeKey="created_time"
            sectionName={`${userName}'s Sound Assets`}
          />
        </Grid>

        {audioUrl && (
          <Grid item xs={12} my={2}>
            <MDBox bgColor="white" shadow="sm" borderRadius="lg" p={2}>
              <AudioPlayer
                autoPlay
                src={audioUrl}
                // onPlay={(e) => console.log("Playing")}
                showJumpControls={false} // Hide forward and backward jump controls
                customAdditionalControls={[]} // Hide extra controls
                customVolumeControls={[]} // Hide volume controls if needed
                layout="horizontal" // Set the layout style
                style={{
                  boxShadow: "none",
                  padding: "0.5rem",
                }}
                header={
                  <>
                    <MDTypography variant="body2">{audioInfo.type}</MDTypography>
                    <MDTypography variant="caption">{audioInfo.text}</MDTypography>
                  </>
                }
              />
            </MDBox>
          </Grid>
        )}
      </Grid>

      <MDSnackbar
        color="error"
        icon="warning"
        title="Notification"
        content={errorSnackbarMessage}
        dateTime=""
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        close={() => setShowSnackbar(false)}
      />
    </>
  );
}

ProfileAssets.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default ProfileAssets;
