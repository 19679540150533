/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types"; // Import PropTypes for validation
import Pagination from "@mui/material/Pagination"; // Import Pagination
import { Tooltip } from "@mui/material"; // Import the Tooltip component

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Jellybean React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function History({
  historyItems,
  canPlay = true,
  canDownload = true,
  handlePlayClick,
  handleDownloadClick,
  itemIdKey,
  itemTimeKey,
  sectionName = "History",
  itemsPerPage = 7,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 5; // Set how many items per page

  // Calculate pagination data
  const totalPages = Math.ceil(historyItems.length / itemsPerPage);
  const displayedItems = historyItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  // Function to truncate text and display tooltip
  const truncateText = (text, length = 20) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {sectionName}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {displayedItems.length > 0 ? (
          displayedItems.map((item, index) => (
            <TimelineItem
              key={index}
              color="info"
              icon="payment"
              title={
                <Tooltip title={item.text} arrow>
                  <span>{truncateText(item.text, 20)}</span>
                </Tooltip>
              }
              dateTime={new Date(item[itemTimeKey]).toLocaleString()}
              lastItem={index === displayedItems.length - 1}
              action={
                canPlay && (
                  <IconButton
                    color="info"
                    size="small"
                    onClick={() => handlePlayClick(item[itemIdKey], item.text)}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                )
              }
              download={
                canDownload && (
                  <IconButton
                    color="info"
                    size="small"
                    onClick={() => handleDownloadClick(item[itemIdKey])}
                  >
                    <DownloadIcon />
                  </IconButton>
                )
              }
            />
          ))
        ) : (
          <MDTypography variant="body2" fontWeight="regular">
            No history available.
          </MDTypography>
        )}
      </MDBox>
      <MDBox display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          color="info"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "0.75rem",
              padding: "4px",
              marginBottom: "8px", // Adding space between number and bottom
            },
          }}
        />
      </MDBox>
    </Card>
  );
}

// PropTypes validation for historyItems
History.propTypes = {
  historyItems: PropTypes.arrayOf(
    PropTypes.shape({
      // uuid: PropTypes.string.isRequired, // Ensure 'uuid' is a required string
      text: PropTypes.string.isRequired, // Ensure 'text' is a required string
      // created: PropTypes.string.isRequired, // Ensure 'created' is a required string
    })
  ).isRequired, // Ensure historyItems is an array and required
  canPlay: PropTypes.bool,
  canDownload: PropTypes.bool,
  handlePlayClick: PropTypes.func, // Ensure the play function is passed
  handleDownloadClick: PropTypes.func, // Ensure the download function is passed
  itemIdKey: PropTypes.string.isRequired,
  itemTimeKey: PropTypes.string.isRequired,
  sectionName: PropTypes.string,
  itemsPerPage: PropTypes.number,
};

export default History;
