/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useAuth0 } from "@auth0/auth0-react"; // Add Auth0 hook

import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player";
import axios from "axios";

// @mui material components
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Jellybean React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui icons
import StoryIcon from "@mui/icons-material/AutoStories";
import JokeIcon from "@mui/icons-material/EmojiEmotions";
import AdIcon from "@mui/icons-material/Campaign";
import MultilingualIcon from "@mui/icons-material/Translate";
import MovieIcon from "@mui/icons-material/Theaters";
import GameIcon from "@mui/icons-material/SportsEsports";

// Dashboard components
// import History from "layouts/SoundDesign/components/History"; // Assuming you have this import

function MusicStudio() {
  const [scriptText, setScriptText] = useState(
    "Welcome to the land of Jellybean, where heroes are born and legends find their voice. Need a dramatic monologue, a quirky NPC conversation, or intense battle commands? Start creating now!"
  ); // State to store the script text
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [historyData, setHistoryData] = useState([]); // State to store the history data
  const [responseMessage, setResponseMessage] = useState(""); // State to store the API response message
  const [audioUrl, setAudioUrl] = useState(""); // State to store the audio URL for playback
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [selectedVoice, setSelectedVoice] = useState(""); // To store the selected speaker_id

  const { getAccessTokenSilently } = useAuth0(); // Get access token hook from Auth0

  // Function to handle voice selection
  const handleVoiceSelection = (speakerId) => {
    setSelectedVoice(speakerId);
  };

  // Fetch history data from /api/query-tts-responses
  const fetchHistory = async () => {
    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/query-tts-responses`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setHistoryData(response.data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  // Fetch history when the component mounts
  useEffect(() => {
    fetchHistory();
  }, []);

  // Handle the Generate button click
  const handleGenerateClick = async () => {
    if (scriptText.trim() === "") {
      setResponseMessage("Please enter a script to generate speech.");
      openErrorSB(); // Open error notification
      return;
    }

    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      // Prepare the payload
      const payload = {
        speaker_id: selectedVoice, // Ensure the selected voice is sent
        job_specs: [{ text: scriptText }], // Use the script text as input for TTS generation
        extensions: ["wav"], // Define the required format
        sample_rate: 44100, // Default sample rate
        bit_rate: 128, // Default bit rate
        global_pace: 1, // Default pace
        model_chain: "vox_1_0", // Default model
        language_code: "en", // Language for TTS
        global_pitch: "0", // Default pitch
        auto_pitch: true, // Enable automatic pitch adjustments
        global_volume: 0, // Default volume
      };

      // Submit the script for TTS generation with the token in the headers
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/submit-tts`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
          },
        }
      );

      setResponseMessage("TTS request submitted successfully.");
      fetchHistory(); // Refresh the history after submission
      setLoading(false);
      openSuccessSB(); // Open success notification
    } catch (error) {
      console.error("Error submitting TTS request:", error);
      setResponseMessage("Failed to submit TTS request.");
      setLoading(false);
      openErrorSB(); // Open error notification
    }
  };

  // Handle play action for a specific UUID
  const handlePlayClick = async (uuid) => {
    setAudioUrl("");
    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API
      });

      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/${uuid}.wav`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const audioUrl = response.data.url; // Get the audio URL from the response
      setAudioUrl(audioUrl); // Store the audio URL in state
      setResponseMessage("Audio is ready for playback.");
      setLoading(false);
      openSuccessSB(); // Open success notification
    } catch (error) {
      console.error("Error downloading audio:", error);
      setResponseMessage("Failed to download audio.");
      setLoading(false);
      openErrorSB(); // Open error notification
    }
  };

  // Handle download action for a specific UUID
  const handleDownloadClick = async (uuid) => {
    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API
      });

      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/${uuid}.wav`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const downloadUrl = response.data.url; // Get the download URL from the response

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${uuid}.wav`); // Set the download attribute with the filename
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to start download
      document.body.removeChild(link); // Remove the link after the download starts

      setResponseMessage("Download started successfully.");
      openSuccessSB(); // Open success notification
    } catch (error) {
      console.error("Error downloading audio:", error);
      setResponseMessage("Failed to download audio.");
      openErrorSB(); // Open error notification
    } finally {
      setLoading(false);
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="notifications"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ height: "100%", boxShadow: "none", textAlign: "center" }}>
                <MDBox pt={4} pb={3} px={3}>
                  <MDTypography variant="h3" fontWeight="medium">
                    Coming Soon
                  </MDTypography>
                  <MDTypography variant="body1" mt={2}>
                    The Music Studio feature is under development. Stay tuned for updates!
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Render success and error snackbars */}
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default MusicStudio;
